import './styles/infoCardProfile.css';
import SwiperInfoCard from './SwiperInfoCard';
import SocialIcons from '../molecules/SocialIcons';
import { TypeAnimation } from 'react-type-animation';
import CustomButton from '../molecules/CustomButton';
import { faDownload, faContactCard } from '@fortawesome/free-solid-svg-icons'

const GeneralInfoCardProfile = () => {

    return (
        <div className="info-card">
            <div className="profile">
                <div className="profile-content">
                    <div className="slide">
                        <SwiperInfoCard />
                    </div>
                    <div className="image">
                        <img className="profile-image" rel="image_src" id='profilePicture' src="https://www.serbeld.space/profilePicture.jpeg" alt="Sergio Beleño - Technical Lead (VTEX) | Full Stack Web Developer" height={220} width={220} title="Profile picture - Sergio Beleño" />
                    </div>
                    <p className="title">Sergio Beleño</p>
                    <p className="subtitle">
                        <TypeAnimation
                            sequence={[
                                'Technical Lead (VTEX)',
                                3000,
                                'Full Stack Web Developer',
                                3000,
                            ]}
                            wrapper="span"
                            cursor={false}
                            speed={50}
                            style={{ display: 'inline-block' }}
                            repeat={Infinity}
                        />
                    </p>
                    <div className="social">
                        <SocialIcons />
                    </div>
                </div>
                <div className="buttons-info-card flex-center">
                    <CustomButton
                        text='Download CV'
                        icon={faDownload}
                        href='https://www.serbeld.space/Full-stack-web-developer-Sergio-Beleño.pdf'
                        blank={true}
                    />
                    <CustomButton
                        text='Contact Me'
                        icon={faContactCard}
                        href='#/contact'
                        blank={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default GeneralInfoCardProfile;